<!-- 用户详情--基本信息模块 -->
<template>
  <div class="user-info-edit">
    <Form ref="formInline" :model="formInline" :rules="ruleInline" :label-width="100">
      <div class="section">
        <div class="section-bd">
          <div class="item">
            <FormItem label="用户昵称：">
              <Input type="text" v-model="formInline.nickname" style="width:300px"></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="用户类型：">
              <Select v-model="formInline.userType" style="width:300px">
                <Option :value="1">普通用户</Option>
                <Option :value="2">团长</Option>
                <!-- <Option value="3">会员</Option> -->
              </Select>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="用户ID：">
              <Input type="text" v-model="formInline.id" disabled style="width:300px"></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="邀请码：">
              <Input type="text" v-model="formInline.invitationCode" disabled style="width:300px"></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="手机号码：">
              <Input
                type="text"
                v-model="formInline.mobile"
                placeholder="请输入手机号码"
                style="width:300px"
              ></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="账号状态：">
              <Select value="1" style="width:300px">
                <Option value="1">正常</Option>
                <Option value="2">冻结</Option>
              </Select>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="个性签名：">
              <Input
                type="textarea"
                v-model="formInline.signRemark"
                style="width:300px"
                placeholder="请输入个性签名"
              ></Input>
            </FormItem>
          </div>
          <!-- <div class="item">
            <FormItem label="户籍地址：">
              <Input type="text" placeholder="请输入户籍地址" style="width:300px"></Input>
            </FormItem>
          </div>-->
        </div>
      </div>
      <!-- <div class="section">
        <div class="section-hd">密码</div>
        <div class="section-bd">
          <div class="item">
            <FormItem label="登陆密码：">
              <Input type="text" placeholder="请输入登录密码（修改用户可不填写，不填写不修改原密码）" style="width:300px"></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="确认密码：">
              <Input type="text" placeholder="请输入确认密码（修改用户可不填写，不填写不修改原密码）" style="width:300px"></Input>
            </FormItem>
          </div>
        </div>
      </div>-->
      <!-- <div class="section">
        <div class="section-hd">用户概况</div>
        <div class="section-bd">
          <div class="item">
            <div>推广资格：</div>
            <div class="value">开启</div>
          </div>
          <div class="item">
            <div>用户状态：</div>
            <div class="value">开启</div>
          </div>
          <div class="item">
            <div>用户等级：</div>
            <div class="value">-</div>
          </div>
          <div class="item">
            <div>用户标签：</div>
            <div class="value">-</div>
          </div>
          <div class="item">
            <div>用户分组：</div>
            <div class="value">-</div>
          </div>
          <div class="item">
            <div>推广人：</div>
            <div class="value">（0）</div>
          </div>
          <div class="item">
            <div>注册时间：</div>
            <div class="value">2023-11-14 15:07:04</div>
          </div>
          <div class="item">
            <div>登录时间：</div>
            <div class="value">2023-11-14 15:07:05</div>
          </div>
        </div>
      </div>-->
      <!-- <div class="section">
        <div class="section-hd">用户备注</div>
        <div class="section-bd">
          <div class="item">
            <FormItem label="用户备注：">
              <Input type="textarea" placeholder="请输入用户备注" style="width:400px" :rows="3"></Input>
            </FormItem>
          </div>
        </div>
      </div>-->
    </Form>
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  created() {
    this.formInline = JSON.parse(JSON.stringify(this.currentData))
  },
  data() {
    return {
      formInline: {
        id: '',
        mobile: '',
        nickname: '',
        userType: '',
        invitationCode: '',
        signRemark: ''
      },
      ruleInline: {
        user: [
          { required: true, message: 'Please fill in the user name', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please fill in the password.', trigger: 'blur' },
          { type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
  }

}
</script>
<style lang="scss">
.user-info-edit {
  padding: 0 20px;
  .ivu-form-item {
    margin-bottom: 6px;
  }
  .section {
    // padding: 25px 0 0;
    // border-bottom: 1px dashed #eee;
  }
  .section:first-child {
    // padding: 10px 0 15px;
  }
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 50%;
      display: flex;
      font-size: 14px;
      color: #666;
      line-height: 32px;
    }
    .item:last-child {
      flex: 0 0 calc(100% - 20px);
    }
  }
}
</style>