<!-- 用户详情--基础信息模块 -->
<template>
  <div class="user-info">
    <div class="section">
      <div class="section-bd">
        <div class="item">
          <div class="label">用户昵称：</div>
          <div class="value">{{currentData.nickname}}</div>
        </div>
        <div class="item">
          <div class="label">用户类型：</div>
          <div class="value">{{currentData.userType==1?'普通用户':'团长'}}</div>
        </div>
        <div class="item">
          <div class="label">用户ID：</div>
          <div class="value">{{currentData.id}}</div>
        </div>
        <div class="item">
          <div class="label">邀请码：</div>
          <div class="value">{{currentData.invitationCode}}</div>
        </div>
        <div class="item">
          <div class="label">手机号码：</div>
          <div class="value">{{currentData.mobile}}</div>
        </div>
        <div class="item">
          <div class="label">账号状态：</div>
          <div class="value">正常</div>
        </div>
        <div class="item">
          <div class="label">个性签名：</div>
          <div class="value">{{currentData.signRemark}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="section">
      <div class="section-hd">密码</div>
      <div class="section-bd">
        <div class="item">
          <div>登录密码：</div>
          <div class="value">********</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-hd">用户概况</div>
      <div class="section-bd">
        <div class="item">
          <div>推广资格：</div>
          <div class="value">开启</div>
        </div>
        <div class="item">
          <div>用户状态：</div>
          <div class="value">开启</div>
        </div>
        <div class="item">
          <div>用户等级：</div>
          <div class="value">-</div>
        </div>
        <div class="item">
          <div>用户标签：</div>
          <div class="value">{{currentData.tag}}</div>
        </div>
        <div class="item">
          <div>用户分组：</div>
          <div class="value">-</div>
        </div>
        <div class="item">
          <div>推广人：</div>
          <div class="value">（0）</div>
        </div>
        <div class="item">
          <div>注册时间：</div>
          <div class="value">2023-11-14 15:07:04</div>
        </div>
        <div class="item">
          <div>登录时间：</div>
          <div class="value">2023-11-14 15:07:05</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-hd">用户备注</div>
      <div class="section-bd">
        <div class="item">
          <div>备注：</div>
          <div class="value">-</div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
    }
  },

}
</script>
<style lang="scss" scoped>
.user-info {
  padding: 0 20px;
  .section {
    padding: 0 0 18px;
    // border-bottom: 1px dashed #eee;
  }
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 0 0 calc(50% - 20px);
      display: flex;
      margin: 0 20px 6px 0;
      font-size: 14px;
      color: #666;
      line-height: 34px;
      .label {
        width: 100px;
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        color: #515a6e;
        line-height: 1;
        padding: 10px 12px 10px 0;
        box-sizing: border-box;
      }
    }
    .item:last-child {
      flex: 0 0 calc(100% - 20px);
    }
  }
}
</style>