<!-- 用户详情--团长推广成员模块 -->
<template>
  <div class="extension-member">
    <div class="table-area">
      <Table size="small" :columns="columns" :data="dataList"></Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page :total="total" :current="current" show-total :page-size="size" @on-change="pageChage"></Page>
    </div>
  </div>
</template>

<script>

import { getColonelSpread } from '@/api/colonelDistribution.js'

export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
    dateVal: null,
  },
  data() {
    return {
      current: 1,
      size: 5,
      total: 0,
      columns: [
        {
          title: '下级成员',
          key: 'name',
        },
        {
          title: '加入时间',
          key: 'createTime',
          width: 160,
        },
        {
          title: '手机号',
          key: 'mobile',
        },
        {
          title: '活动收益',
          key: 'colonelEarnings',
        },
        {
          title: '用户消费',
          key: 'userEarnings',
        },
        {
          title: '商家流水',
          key: 'storeEarnings',
        },
        {
          title: '分享注册',
          key: 'userShareEarnings',
        },
        {
          title: '收益圈豆',
          key: 'integralEarnings',
        },
      ],
      dataList: [],
    }
  },
  watch: {
    dateVal() {
      this.current = 1
      this.findPageDataList()
    },
  },
  mounted() {
    this.findPageDataList()
  },
  methods: {
    findPageDataList() {
      let params = { userId: this.currentData.id, pageIndex: this.current, size: this.size }
      if (this.dateVal) {
        params.startDate = this.getMonthRange(this.dateVal).startDate
        params.endDate = this.getMonthRange(this.dateVal).endDate
      }
      getColonelSpread(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
  }

}
</script>
<style lang="scss" scoped>
.extension-member {
  background: #fff;
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    background: #f7f8fa;
    .item {
      flex: 0 0 calc(33% - 20px);
      display: flex;
      line-height: 30px;
      font-size: 13px;
      color: #666;
    }
  }
}
</style>