<!-- 用户详情--团长绩效考核模块 -->
<template>
  <div class="performance-appraisal">
    <div class="date-change-area">
      <Select v-model="date" style="width:200px">
        <Option v-for="(item,key) in dateList" :value="item.id" :key="key">
          {{
          item.examineStart+'~'+item.examineEnd
          }}
        </Option>
      </Select>
    </div>
    <div class="table-area">
      <Table border size="small" :columns="columns" :data="dataList" :span-method="handleSpan">
        <template slot-scope="{  }" slot="avgScore">{{avgScore}}</template>
        <template slot-scope="{  }" slot="currentEarnings">{{currentEarnings}}</template>
      </Table>
    </div>
    <!-- <div class="page-area" v-if="dataList.length">
      <Page :total="total" :current="current" show-total :page-size="size" @on-change="pageChage"></Page>
    </div>-->
  </div>
</template>

<script>

import { getTaskList, getTaskQuarterList } from '@/api/colonelDistribution.js'

export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      current: 1,
      size: 5,
      total: 0,
      columns: [
        {
          title: '当前季度',
          key: 'date',
          width: 160
        },
        {
          title: '带团5次',
          key: 'dt',
        },
        {
          title: '推广团长5名',
          key: 'tgtz',
        },
        {
          title: '推广商家1户',
          key: 'tgsj',
        },
        {
          title: '推广用户40名',
          key: 'tgyh',
        },
        {
          title: '营业额10000元',
          key: 'yye',
        },
        {
          title: '阶段考核分',
          key: 'score',
        },
        {
          title: '考核评分',
          key: 'examineAvgScore',
          slot: 'avgScore'
        },
        {
          title: '收益比例',
          key: 'earningsScale',
          slot: 'currentEarnings'
        }
      ],
      dataList: [],
      dateList: [],
      date: '',
      avgScore: '',
      currentEarnings: '',
    }
  },
  watch: {
    date() {
      this.findPageDataList()
    },
  },
  mounted() {
    // this.findPageDataList()
    this.getDateList()
  },
  methods: {
    handleSpan({ row, column, rowIndex, columnIndex }) {
      if (this.size > 20) {
        console.log(row, column, rowIndex, columnIndex)
      }
      if (column.key === 'examineAvgScore' || column.key === 'earningsScale') {
        // 对 '考核评分' 和 '收益比例' 列进行跨行合并
        return { rowspan: this.dataList.length, colspan: 1 }; // 跨两行，不跨列
      }
    },
    findPageDataList() {
      let params = { userId: this.currentData.id }
      params.userExamineId = this.date
      getTaskList(params).then(res => {
        if (res.success) {
          this.avgScore = res.result.avgScore
          this.currentEarnings = res.result.currentEarnings
          this.dataList = []
          res.result.currentScore.forEach(v => {
            let obj = { date: v.date, score: v.score, dt: 0, tgtz: 0, tgsj: 0, tgyh: 0, yye: 0, }
            res.result.currentTaskList.forEach(item => {
              res.result.taskList.forEach(val => {
                if (v.date == val.taskTime) {
                  if (item.taskCode == 'tourGroupLeaderTask' && item.taskCode == val.taskCode) {
                    obj.dt += 5
                  }
                  if (item.taskCode == 'recommendedGroupLeaderTask' && item.taskCode == val.taskCode) {
                    obj.dttz++
                  }
                  if (item.taskCode == 'storeGroupLeaderTask' && item.taskCode == val.taskCode) {
                    obj.dtsj++
                  }
                  if (item.taskCode == 'storeGroupLeaderTask' && item.taskCode == val.taskCode) {
                    obj.dtyh += 40
                  }
                  if (item.taskCode == 'tourTurnoverGroupLeaderTask' && item.taskCode == val.taskCode) {
                    obj.yye += 10000
                  }
                }
              })
            })
            this.dataList.push(obj)
            // this.total = res.result.total
          })
        }
      })
    },
    getDateList() {
      let params = { userId: this.currentData.id }
      getTaskQuarterList(params).then(res => {
        if (res.success) {
          this.dateList = res.result
          if (res.result.length) {
            this.date = res.result[0].id
          }
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
  }
}
</script>
<style lang="scss" scoped>
.performance-appraisal {
  position: relative;
  background: #fff;
  .date-change-area {
    position: absolute;
    right: 0;
    top: -58px;
    z-index: 2;
  }
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    background: #f7f8fa;
    .item {
      flex: 0 0 calc(33% - 20px);
      display: flex;
      line-height: 30px;
      font-size: 13px;
      color: #666;
    }
  }
}
</style>