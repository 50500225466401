<!-- 用户管理页面 -->
<template>
  <div class="memberManagement">
    <div class="head-area clr">
      <div class="fl clr left-search">
        <div class="fl search-item">
          <div class="fl head-label-area">用户ID</div>
          <Input class="fl" v-model="id" placeholder="请输入用户ID" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">用户昵称</div>
          <Input class="fl" v-model="nickName" placeholder="请输入用户昵称" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">手机号</div>
          <Input class="fl" v-model="mobile" placeholder="请输入手机号" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">用户类型</div>
          <Select v-model="userType" clearable style="width:200px">
            <Option value="1">普通用户</Option>
            <Option value="2">团长</Option>
            <!-- <Option value="3">会员</Option> -->
          </Select>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">认证状态</div>
          <Select v-model="status" clearable style="width:200px">
            <Option value="2">已认证</Option>
            <Option value="0">未认证</Option>
            <Option value="1">只认证二要素未人脸认证</Option>
            <Option value="3">实名未通过</Option>
            <Option value="4">黑名单</Option>
            <Option value="5">注销</Option>
          </Select>
        </div>
        <!-- <div class="fl search-item">
          <div class="fl head-label-area">账号状态</div>
          <Select v-model="status1" clearable style="width:200px">
            <Option value="1">正常</Option>
            <Option value="2">冻结</Option>
          </Select>
        </div>-->
      </div>
      <div class="fr right-search-btn">
        <Button type="primary" style="margin:0 0 10px;" icon="ios-search" @click="search">查询</Button>
        <Button @click="reset" icon="ios-refresh">重置</Button>
      </div>
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" :height="tableHeight">
        <template #avatar="{ row }">
          <img style="width:36px;height:36px;margin-top:5px" :src="row.avatar" alt />
        </template>
        <template #userType="{ row }">
          <div v-if="row.userType == 1">普通用户</div>
          <div v-if="row.userType == 2">团长</div>
        </template>
        <template #status="{ row }">
          <div v-if="row.status == 0">未实名认证</div>
          <div v-if="row.status == 1">只认证二要素未人脸认证</div>
          <div v-if="row.status == 2">已实名认证</div>
          <div v-if="row.status == 3">实名未通过</div>
          <div v-if="row.status == 4">黑名单</div>
          <div v-if="row.status == 5">注销</div>
        </template>
        <template #action="{ row }">
          <a @click="checkDetial(row)">详情</a>
          <a style="color:red;margin-left:5px" @click="delRow(row)">删除</a>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <!-- <Drawer title="用户详情" v-model="modelShow" width="1100" :mask-closable="false" :styles="styles"> -->
    <div class="detial-page-area" v-if="modelShow">
      <detialBox @editSuccess="findPageDataList()"  @close="modelShow = false" v-if="modelShow" :currentData="currentData" />
    </div>
    <!-- </Drawer> -->
  </div>
</template>

<script>
import detialBox from './components/detialBox.vue'
import { findMemberPageList, deleteMember } from '@/api/memberCenter.js'
export default {
  components: {
    detialBox
  },
  data() {
    return {
      modelShow: false,
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      id: '',
      userType: '',
      status: '',
      mobile: '',
      nickName: '',
      status1: '',
      tableHeight: window.innerHeight - 310,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          type: 'expand',
          width: 50,
          render: (h, { row }) => {
            return h('div', this.expandFun(row, h))
          }
        },
        {
          title: '用户ID',
          key: 'id',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '头像',
          key: 'avatar',
          slot: 'avatar',
          width: 80
        },
        {
          title: '用户昵称',
          key: 'nickname',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '用户类型',
          key: 'userType',
          slot: 'userType',
          width: 100
        },
        {
          title: '手机号',
          key: 'mobile',
        },
        {
          title: '邀请码',
          key: 'invitationCode',
        },
        // {
        //   title: 'VIP等级',
        //   key: 'vipLevel'
        // },
        {
          title: '认证状态',
          key: 'status',
          slot: 'status',
          width: 100
        },
        // {
        //   title: '余额',
        //   key: 'brokerage_price'
        // },
        {
          title: '操作',
          slot: 'action',
          width: 120
        }
      ],
      dataList: [
        // {
        //   "uid": 48907,
        //   "nickname": "fwad",
        //   "real_name": "fwad",
        //   "avatar": "https://demo26.crmeb.net/statics/system_images/default_avatar.jpeg",
        //   "phone": "155****8881",
        //   "add_time": 1699704695,
        //   "last_time": 1699704708,
        //   "now_money": "0.00",
        //   "brokerage_price": "0.00",
        //   "integral": "0.00",
        //   "exp": "10000.00",
        //   "sign_num": 0,
        //   "sign_remind": 0,
        //   "status": "正常",
        //   "level": "荣耀",
        //   "agent_level": 0,
        //   "spread_open": 1,
        //   "spread_uid": 0,
        //   "spread_time": 0,
        //   "user_type": "H5",
        //   "is_promoter": 1,
        //   "pay_count": 0,
        //   "spread_count": 0,
        //   "login_type": "",
        //   "birthday": "",
        //   "card_id": "",
        //   "mark": "",
        //   "group_id": "测试",
        //   "addres": "",
        //   "adminid": 0,
        //   "record_phone": "0",
        //   "is_money_level": 0,
        //   "is_ever_level": 0,
        //   "overdue_time": 0,
        //   "uniqid": "",
        //   "division_type": 0,
        //   "division_status": 0,
        //   "is_division": 0,
        //   "is_agent": 0,
        //   "is_staff": 0,
        //   "division_id": 0,
        //   "agent_id": 0,
        //   "staff_id": 0,
        //   "division_percent": 0,
        //   "division_change_time": 0,
        //   "division_end_time": 0,
        //   "division_invite": 0,
        //   "is_del": 0,
        //   "country": null,
        //   "province": null,
        //   "city": null,
        //   "sex": "保密",
        //   "unionid": null,
        //   "openid": null,
        //   "w_user_type": null,
        //   "groupid": null,
        //   "tagid_list": null,
        //   "subscribe": null,
        //   "subscribe_time": null,
        //   "extract_count_price": 0,
        //   "spread_uid_nickname": "无",
        //   "vip_name": "荣耀",
        //   "labels": "华中1,大幅度,上上,1111",
        //   "isMember": 0
        // }
      ],
      currentData: {}
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    reset() {
      this.id = ''
      this.userType = ''
      this.status = ''
      this.mobile = ''
      this.nickName = ''
      this.current = 1
      this.findPageDataList()
    },
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      let params = { pageIndex: this.current, size: this.size }
      params.nickName = this.nickName
      params.id = this.id
      params.status = this.status
      params.userType = this.userType
      params.mobile = this.mobile
      // if (this.searchType == 1 && this.searchText) {
      //   params.nickName = this.searchText
      // } else if (this.searchType == 2 && this.searchText) {
      //   params.id = this.searchText
      // }
      findMemberPageList(params).then(res => {
        console.log(res)
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    checkDetial(row) {
      this.modelShow = true
      this.currentData = JSON.parse(JSON.stringify(row))
    },
    delRow(row) {
      this.$Modal.confirm({
        title: "确认删除？",
        onOk: () => {
          deleteMember({ id: row.id }).then(res => {
            if (res.success) {
              this.$Message.success('删除成功！')
              this.findPageDataList()
            }
          })
        },
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    expandFun(row, h) {
      return [
        h('div', {
          style: {
            display: 'inline-block',
            marginBottom: '16px',
            width: '25%'
          },
        }, "身份证号：" + (row.idCard ? row.idCard : '')),
        h('div', {
          style: {
            display: 'inline-block',
            marginBottom: '16px',
            width: '25%'
          },
        }, "真实姓名：" + (row.userName ? row.userName : '')),
        h('div', {
          style: {
            display: 'inline-block',
            marginBottom: '16px',
            width: '25%'
          },
        }, "标签：" + (row.tag ? row.tag : '')),
        h('div', {
          style: {
            display: 'inline-block',
            width: '25%'
          },
        }, "生日：" + (row.birthday ? row.birthday : '')),
        h('div', {
          style: {
            display: 'inline-block',
            width: '25%'
          },
        }, "推荐人：" + (row.parentName ? row.parentName : ''))
        // h('div', {
        //   style: {
        //     display: 'inline-block',
        //     width: '25%'
        //   },
        // }, "地址：" + (row.addres?row.addres:''))
        // h('div', {
        //   style: {
        //     display: 'inline-block',
        //     width: '25%'
        //   },
        // }, "备注：" + (row.labels?row.labels:''))
      ]
    }
  }
}

</script>
<style lang='scss' scoped>
.memberManagement {
  .left-search {
    width: 900px;
    .search-item {
      margin-bottom: 10px;
    }
  }
  .right-search-btn {
    width: 100px;
    border-left: 1px solid #e5e6eb;
    padding-left: 20px;
  }
  .head-label-area {
    line-height: 36px;
    width: 80px;
    padding-right: 15px;
    text-align: right;
  }
    .detial-page-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // padding: 20px;
    background: #f5f7f9;
    z-index: 100;
    overflow: auto;
  }
}
</style>