<!-- 用户详情--基本信息模块 -->
<template>
  <div class="base-info">
    <div class="section-bd">
      <div class="item">
        <div class="label">真实姓名：</div>
        <div class="value">{{currentData.userName}}</div>
      </div>
      <div class="item">
        <div class="label">注册时间：</div>
        <div class="value">{{currentData.registerTime}}</div>
      </div>
      <div class="item">
        <div class="label">性别：</div>
        <div class="value">{{currentData.sex == 1?'男':'女'}}</div>
      </div>
      <div class="item">
        <div class="label">身份证号：</div>
        <div class="value">{{currentData.idCard}}</div>
      </div>
      <div class="item">
        <div class="label">生日：</div>
        <div class="value">{{currentData.birthday}}</div>
      </div>
      <div class="item">
        <div class="label">年龄：</div>
        <div class="value">{{currentData.age}}</div>
      </div>
      <div class="item">
        <div class="label">认证状态：</div>
        <div class="value">
          <span v-if="currentData.status == 0">未认证</span>
          <span v-if="currentData.status == 1">只认证二要素未人脸认证</span>
          <span v-if="currentData.status == 2">已认证</span>
          <span v-if="currentData.status == 3">实名未通过</span>
          <span v-if="currentData.status == 4">黑名单</span>
          <span v-if="currentData.status == 5">注销</span>
        </div>
      </div>
      <div class="item">
        <div class="label">认证时间：</div>
        <div class="value">{{currentData.authTime}}</div>
      </div>
      <div class="item">
        <div class="label">推荐人：</div>
        <div class="value">{{currentData.parentName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
    }
  },

}
</script>
<style lang="scss" scoped>
.base-info {
  padding: 10px 20px;
  background: #fff;
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 0 0 calc(33% - 20px);
      display: flex;
      margin: 0 20px 16px 0;
      font-size: 13px;
      color: #666;
    }
    // .item:last-child {
    //   flex: 0 0 calc(100% - 20px);
    // }
  }
}
</style>