<!-- 用户详情--活动参与模块 -->
<template>
  <div class="base-info">
    <div class="section-bd">
      <div class="item">
        <div class="label">总订单数：</div>
        <div class="value">{{detialMsg.orderTotal}}</div>
      </div>
      <div class="item">
        <div class="label">本月订单数：</div>
        <div class="value">{{detialMsg.orderCurrent}}</div>
      </div>
      <div class="item">
        <div class="label">上月订单数：</div>
        <div class="value">{{detialMsg.orderLast}}</div>
      </div>
      <div class="item">
        <div class="label">总消费额：</div>
        <div class="value">{{detialMsg.amountTotal}}</div>
      </div>
      <div class="item">
        <div class="label">本月消费额：</div>
        <div class="value">{{detialMsg.amountCurrent}}</div>
      </div>
      <div class="item">
        <div class="label">上月消费额：</div>
        <div class="value">{{detialMsg.amountLast}}</div>
      </div>
    </div>
    <div class="table-area">
      <Table size="small" :columns="columns" :data="dataList">
         <template #qrCodeStatus="{ row }">
          <span v-if="row.qrCodeStatus == 0">未使用</span>
          <span v-if="row.qrCodeStatus == 1">已使用</span>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page :total="total" :current="current" show-total :page-size="size" @on-change="pageChage"></Page>
    </div>
  </div>
</template>

<script>
import { getParticipateActivitiesInfo, getParticipateActivitiesList } from '@/api/memberCenter.js'

export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
    dateVal: null,
  },
  data() {
    return {
      detialMsg: {
        amountLast: 0,
        orderLast: 0,
        orderCurrent: 0,
        amountCurrent: 0,
        amountTotal: 0,
        orderTotal: 0
      },
      current: 1,
      size: 5,
      total: 0,
      columns: [
        {
          title: '团订单id',
          key: 'groupPurchaseId',
        },
        {
          title: '活动名称',
          key: 'groupPurchaseTitle',
        },
        {
          title: '支付金额(元)',
          key: 'payment',
        },
        {
          title: '支付时间',
          key: 'paymentTime',
        },
        {
          title: '核销状态',
          key: 'qrCodeStatus',
          slot: 'qrCodeStatus'
        },
        {
          title: '核销时间',
          key: 'qrCodeTime',
        }
      ],
      dataList: [],
    }
  },
  watch: {
    dateVal() {
      this.current = 1
      this.findPageDataList()
      this.getParticipateActivitiesInfo()
    },
  },
  mounted() {
    this.findPageDataList()
    this.getParticipateActivitiesInfo()
  },
  methods: {
    getParticipateActivitiesInfo() {
      let params = { userId: this.currentData.id }
      if (this.dateVal) {
        params.startDate = this.getMonthRange(this.dateVal).startDate
        params.endDate = this.getMonthRange(this.dateVal).endDate
      }
      getParticipateActivitiesInfo(params).then(res => {
        if (res.success) {
          this.detialMsg = res.result
        }
      })
    },
    findPageDataList() {
      let params = { userId: this.currentData.id, pageIndex: this.current, size: this.size }
      if (this.dateVal) {
        params.startDate = this.getMonthRange(this.dateVal).startDate
        params.endDate = this.getMonthRange(this.dateVal).endDate
      }
      getParticipateActivitiesList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
  }

}
</script>
<style lang="scss" scoped>
.base-info {
  background: #fff;
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    background: #f7f8fa;
    .item {
      flex: 0 0 calc(33% - 20px);
      display: flex;
      line-height: 30px;
      font-size: 13px;
      color: #666;
    }
  }
}
</style>