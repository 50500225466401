<!-- 用户详情--团长活动发布模块 -->
<template>
  <div class="activity-promulgate">
    <div class="table-area">
      <Table size="small" :columns="columns" :data="dataList">
         <template slot-scope="{ row }" slot="beginTime">
          {{row.beginTime }} ~ {{row.endTime}}
        </template>
        <template slot-scope="{ row }" slot="groupPurchaseStatus">
          <span v-if="row.groupPurchaseStatus == 0">待接单</span>
          <span v-if="row.groupPurchaseStatus == 1">拼团中</span>
          <span v-if="row.groupPurchaseStatus == 2">已成团</span>
          <span v-if="row.groupPurchaseStatus == 3">已完成</span>
          <span v-if="row.groupPurchaseStatus == 4">失败</span>
          <span v-if="row.groupPurchaseStatus == 5">商家拒单</span>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page :total="total" :current="current" show-total :page-size="size" @on-change="pageChage"></Page>
    </div>
  </div>
</template>

<script>

import { getColonelGroups } from '@/api/colonelDistribution.js'

export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
    dateVal: null,
  },
  data() {
    return {
      detialMsg: {
        withdrawalMethod: "",
        balance: 0,
        alipayAccount: 0,
        turnover: 0,
        disburse: 0
      },
      current: 1,
      size: 5,
      total: 0,
      columns: [
        {
          title: '发布时间',
          key: 'createTime',
        },
        {
          title: '活动名称',
          key: 'title',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '活动时间',
          key: 'beginTime',
          slot: 'beginTime'
        },
        {
          title: '活动商家',
          key: 'storeName',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '活动人数',
          key: 'joinUserSize',
        },
        {
          title: '活动价格(元)',
          key: 'setMealPrice',
        },
        {
          title: '活动状态',
          key: 'groupPurchaseStatus',
          slot:'groupPurchaseStatus'
        }
      ],
      dataList: [],
    }
  },
  watch: {
    dateVal() {
      this.current = 1
      this.findPageDataList()
    },
  },
  mounted() {
    this.findPageDataList()
  },
  methods: {
    findPageDataList() {
      let params = { userId: this.currentData.id, pageIndex: this.current, size: this.size }
      if (this.dateVal) {
        params.startDate = this.getMonthRange(this.dateVal).startDate
        params.endDate = this.getMonthRange(this.dateVal).endDate
      }
      getColonelGroups(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
  }

}
</script>
<style lang="scss" scoped>
.activity-promulgate {
  background: #fff;
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    background: #f7f8fa;
    .item {
      flex: 0 0 calc(33% - 20px);
      display: flex;
      line-height: 30px;
      font-size: 13px;
      color: #666;
    }
  }
}
</style>