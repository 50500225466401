<!-- 用户详情--团长信息模块 -->
<template>
  <div class="base-info">
    <div class="title-name">团长信息</div>
    <div class="section-bd">
      <div class="item">
        <div class="label">团长状态：</div>
        <div class="value">
          {{currentData.colonelStatusName}}
          <!-- <span v-if="currentData.status == 0">未认证</span>
          <span v-if="currentData.status == 1">只认证二要素未人脸认证</span>
          <span v-if="currentData.status == 2">已认证</span>
          <span v-if="currentData.status == 3">实名未通过</span>
          <span v-if="currentData.status == 4">黑名单</span>
          <span v-if="currentData.status == 5">注销</span>-->
        </div>
      </div>
      <div class="item">
        <div class="label">申请时间：</div>
        <div class="value">{{currentData.applyColonelTime}}</div>
      </div>
      <div class="item">
        <div class="label">推荐团长：</div>
        <div class="value">{{currentData.parentName}}</div>
      </div>

      <div class="item">
        <div class="label">生效时间：</div>
        <div class="value">{{currentData.colonelStartTime}}</div>
      </div>
      <div class="item">
        <div class="label">到期时间：</div>
        <div class="value">{{currentData.colonelEndTime}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
    }
  },

}
</script>
<style lang="scss" scoped>
.base-info {
  padding: 10px 20px;
  background: #fff;
  .title-name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 0 0 calc(33% - 20px);
      display: flex;
      margin: 0 20px 16px 0;
      font-size: 13px;
      color: #666;
    }
    // .item:last-child {
    //   flex: 0 0 calc(100% - 20px);
    // }
  }
}
</style>