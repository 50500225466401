<!-- 用户详情页面 -->
<template>
  <div class="detial-box">
    <div class="title-name">
      <p>用户详情</p>
      <div class="edit-btn">
        <Button @click="$emit('close')">返回</Button>
      </div>
    </div>
    <div class="head-area clr">
      <div class="avatar fl">
        <img :src="currentData.avatar" alt />
      </div>
      <div class="header-tip fl">
        <userInfo ref="userInfo" v-if="type=='check'&&detailMsg.id" :currentData="detailMsg" />
        <userInfoEdit
          ref="userInfoEdit"
          v-if="type!='check'&&detailMsg.id"
          :currentData="detailMsg"
        />
      </div>
      <div class="edit-btn">
        <Button type="primary" v-if="type=='check'" @click="type = 'edit';tabVal = '1'">编辑</Button>
        <Button v-if="type!='check'" @click="type = 'check'">取消</Button>
        <Button v-if="type!='check'" type="primary" style="margin-left:10px" @click="save">保存</Button>
      </div>
    </div>
    <div class="content-area">
      <div class="model-area">
        <Tabs v-model="tabVal" :animated="false">
          <TabPane label="基础信息" name="1">
            <baseInfo v-if="tabVal==1&&detailMsg.id" :currentData="detailMsg" />
          </TabPane>
          <!-- <TabPane label="消费记录" name="2"></TabPane>
        <TabPane label="积分明细" name="3"></TabPane>
        <TabPane label="签到记录" name="4"></TabPane>
        <TabPane label="持有优惠劵" name="5"></TabPane>
        <TabPane label="余额变动" name="6"></TabPane>
          <TabPane label="好友关系" name="7"></TabPane>-->
        </Tabs>
      </div>
      <div class="model-area" v-if="currentData.userType==2">
        <colonelInfo v-if="detailMsg.id" :currentData="detailMsg" />
      </div>
      <div class="model-area" v-if="currentData.userType==2">
        <div class="date-change">
          <DatePicker
            placement="top"
            v-show="tabVal3!=2"
            v-model="dateVal2"
            type="month"
            placeholder="选择月份"
            style="width: 200px"
          ></DatePicker>
        </div>
        <Tabs v-model="tabVal3" :animated="false">
          <TabPane label="推广成员" name="1">
            <extensionMember v-if="tabVal3 == 1" :currentData="currentData" :dateVal="dateVal2" />
          </TabPane>
          <TabPane label="绩效考核" name="2">
            <performanceAppraisal
              v-if="tabVal3 == 2"
              :currentData="currentData"
            />
          </TabPane>
          <TabPane label="活动发布" :dateVal="dateVal2" name="3">
            <activityPromulgate v-if="tabVal3 == 3" :currentData="currentData" :dateVal="dateVal2" />
          </TabPane>
          <TabPane label="活动收益" :dateVal="dateVal2" name="4">
            <activityIncome v-if="tabVal3 == 4" :currentData="currentData" :dateVal="dateVal2" />
          </TabPane>
        </Tabs>
      </div>
      <div class="model-area">
        <div class="date-change">
          <DatePicker v-model="dateVal" type="month" placeholder="选择月份" style="width: 200px"></DatePicker>
        </div>
        <Tabs v-model="tabVal2" :animated="false">
          <TabPane label="圈豆账户" name="1">
            <beanAccount v-show="tabVal2==1" :currentData="currentData" :dateVal="dateVal" />
          </TabPane>
          <TabPane label="活动参与" name="2">
            <participateActivities
              v-show="tabVal2==2"
              :currentData="currentData"
              :dateVal="dateVal"
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import userInfo from './userInfo.vue'
import userInfoEdit from './userInfoEdit.vue'
import baseInfo from './baseInfo.vue'
import colonelInfo from './colonelInfo.vue'
import extensionMember from './extensionMember.vue'
import performanceAppraisal from './performanceAppraisal.vue'
import activityPromulgate from './activityPromulgate.vue'
import activityIncome from './activityIncome.vue'
import beanAccount from './beanAccount.vue'
import participateActivities from './participateActivities.vue'


import { editMember, detalMember } from '@/api/memberCenter.js'
export default {
  components: {
    userInfo,
    userInfoEdit,
    baseInfo,
    colonelInfo,
    beanAccount,
    participateActivities,
    extensionMember,
    performanceAppraisal,
    activityPromulgate,
    activityIncome
  },
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      type: 'check',
      tabVal: "1",
      tabVal2: "1",
      tabVal3: "1",
      dateVal: null,
      dateVal2: null,
      dateVal3: null,
      dateVal4: null,
      detailMsg: {}
    }
  },
  created() {
    this.detalMember()
  },
  methods: {
    dateFormat(date) {
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    },
    detalMember() {
      detalMember({ id: this.currentData.id }).then(res => {
        if (res.success) {
          this.detailMsg = res.result
        }
      })
    },
    save() {
      let params = this.$refs.userInfoEdit.formInline
      // params.birthday = this.dateFormat(new Date(params.birthday))
      Object.keys(params).forEach(item => {
        if (params[item] == null) {
          params[item] = ''
        }
      })
      editMember(params).then(res => {
        if (res.success) {
          this.type = 'check'
          // this.currentData = params
          this.detailMsg = params
          this.detalMember()
          this.$Message.success('修改成功！')
          this.$emit('editSuccess')
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.detial-box {
  .title-name {
    position: relative;
    background: #fff;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    border-bottom: 1px solid #f6f7f9;
    color: #333;
    p {
      line-height: 50px;
    }
  }
  .head-area {
    position: relative;
    padding: 20px 20px 0;
    background: #fff;
    .avatar {
      width: 60px;
      height: 60px;
      margin-right: 15px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header-tip {
      width: 82%;
      vertical-align: middle;
      .header-tip-title {
        font-size: 13px;
        color: #000;
        margin-bottom: 12px;
      }
      .header-tip-desc-sp {
        width: 33.33%;
        color: #17233d;
        font-size: 13px;
        display: inline-block;
      }
    }
  }
  .edit-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .content-area {
    margin-top: 14px;
    .model-area {
      position: relative;
      padding: 10px 20px;
      background: #fff;
      margin-bottom: 14px;
      .date-change {
        position: absolute;
        right: 20px;
        top: 8px;
        z-index: 2;
      }
    }
  }
}
</style>
