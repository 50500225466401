<!-- 用户详情--团长活动收益模块 -->
<template>
  <div class="activity-income">
    <div class="section-bd">
      <div class="item">
        <div class="label">现金余额：</div>
        <div class="value">{{detialMsg.balance}}</div>
      </div>
      <div class="item">
        <div class="label">收入总额：</div>
        <div class="value">{{detialMsg.turnover}}</div>
      </div>
      <div class="item">
        <div class="label">总提现额：</div>
        <div class="value">{{detialMsg.disburse}}</div>
      </div>
      <div class="item">
        <div class="label">提现方式：</div>
        <div class="value">{{detialMsg.withdrawalMethod}}</div>
      </div>
      <div class="item">
        <div class="label">提现账号：</div>
        <div class="value">{{detialMsg.alipayAccount}}</div>
      </div>
    </div>
    <div class="table-area">
      <Table size="small" :columns="columns" :data="dataList"></Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page :total="total" :current="current" show-total :page-size="size" @on-change="pageChage"></Page>
    </div>
  </div>
</template>

<script>

import { getIntegralInfo, getIntegralList } from '@/api/memberCenter.js'

export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
    dateVal: null,
  },
  data() {
    return {
      detialMsg: {
        withdrawalMethod: "",
        balance: 0,
        alipayAccount: 0,
        turnover: 0,
        disburse: 0
      },
      current: 1,
      size: 5,
      total: 0,
      columns: [
        {
          title: '流水号',
          key: 'id',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '来源',
          key: 'typeName',
        },
        {
          title: '方式',
          key: 'operationTypeName',
        },
        {
          title: '数量',
          key: 'changeAmount',
        },
        {
          title: '入账时间',
          key: 'createTime',
        },
        {
          title: '当前余额',
          key: 'changeAmount',
        }
      ],
      dataList: [],
    }
  },
  watch: {
    dateVal() {
      this.current = 1
      this.findPageDataList()
      this.getIntegralInfo()
    },
  },
  mounted() {
    this.findPageDataList()
    this.getIntegralInfo()
  },
  methods: {
    getIntegralInfo() {
      let params = { userId: this.currentData.id }
      if (this.dateVal) {
        params.startDate = this.getMonthRange(this.dateVal).startDate
        params.endDate = this.getMonthRange(this.dateVal).endDate
      }
      getIntegralInfo(params).then(res => {
        if (res.success) {
          this.detialMsg = res.result
        }
      })
    },
    findPageDataList() {
      let params = { userId: this.currentData.id, pageIndex: this.current, size: this.size }
      if (this.dateVal) {
        params.startDate = this.getMonthRange(this.dateVal).startDate
        params.endDate = this.getMonthRange(this.dateVal).endDate
      }
      getIntegralList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
  }

}
</script>
<style lang="scss" scoped>
.activity-income {
  background: #fff;
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    background: #f7f8fa;
    .item {
      flex: 0 0 calc(33% - 20px);
      display: flex;
      line-height: 30px;
      font-size: 13px;
      color: #666;
    }
  }
}
</style>